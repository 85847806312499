@import '~antd/dist/antd.css';

.App {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App-Home-Grid-Wrapper {
  overflow: auto;
  flex-grow: 2;
}

.ant-drawer-body {
  padding: 0rem;
}