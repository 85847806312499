.view figure img { 
  width: 100%
}

.view figure.topcorner img {
  width: auto;
  padding: 1.5rem;
  padding-left: 3.5rem;
}


.view figure.padded {
  max-width: fit-content;
  padding: 2rem; 
  padding-left: 5rem;
  
  
 
  
}
.view figure.color {
  background-color:#190A1E; 
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.view figure.combine {
  background-color:#F3F3F3; 
  padding-bottom: 2rem;
  padding-top: 2rem; 
}

.view figure.mech {
  background-color:#13181E; 
  padding-bottom: 2rem;
  padding-top: 2rem; 
}

.view figure.square {
  background-color:#01050D; 
  padding-bottom: 2rem;
  padding-top: 2rem; 
}

.view figure.fill {
  padding-top: 5rem;
  max-width:100%;
  
  
}

.view section {
  margin-bottom: 2rem;
}

.view h1 {
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0;
  padding-left: 5rem;
  padding-right: 5rem;
  
}

.view h2 {
  font-weight: medium;
  font-size: 1.4rem;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.view h3 {
  color:#FFFFFF; 
  font-weight: medium;
  font-size: 1.4rem;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.view p.subtitle {
  margin-bottom: 3rem;
  padding-left: 5rem;
}



.view p {
  margin-bottom: .5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
