.App-Header {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  box-shadow: 0 5px 12px rgba(0,0,0,.2);
  z-index: 1;
}

.Header-Home-Link {
  text-transform: uppercase;
  font-weight: 900;
}

.Header-Contact-Link {
  color: grey;
  padding: 2rem;
}
