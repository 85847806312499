.Home-Grid-Card {
  display: block;
  position: relative;
}

.Home-Grid-Card-Img {
  width: 100%;
}

.Home-Grid-Card-Overlay {
  text-align: center;
  position: absolute;
  background: rgba(0,0,0, .7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  backdrop-filter: blur(2px);
  transition: opacity .33s ease-in-out;
}

.Home-Grid-Card:hover .Home-Grid-Card-Overlay {
  opacity: 1;
}

.Home-Grid-Card-Overlay-Title {
  color: white;
  margin-bottom: .5em;
}

.Home-Grid-Card-Overlay-Subtitle {
  color: white;
}
