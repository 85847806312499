



.view p.skills {
  font-size: 1.5rem;
}

.view figure img { 
  width: 100%
}


.view figure.padded {
  max-width: fit-content;
  padding: 2rem; 
  padding-left: 5rem;

}

.view h1 {
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0;
  padding-left: 5rem;
  padding-right: 5rem;
  
}

.view h2 {
  font-weight: medium;
  font-size: 1.4rem;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.view p.subtitle {
  margin-bottom: 3rem;
  padding-left: 5rem;
}



.view p {
  margin-bottom: .5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

